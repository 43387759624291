import axios from 'axios';
import React, { useState } from 'react'
import { Spinner } from 'react-bootstrap';
import { useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import { setIsLogged, setUserDetails } from '../../redux/userSlice';

export default function LoginCard({ setShowSignUp }) {
    const dispatch = useDispatch()
    const API_URL = process.env.REACT_APP_API_URL;
    const [IsLoading, setIsLoading] = useState(false);
    const [errorMessage, setErrorMessage] = useState();
    const [LoginData, setLoginData] = useState({
        email: "",
        password: ""
    });
    const { email, password } = LoginData;
    const onInputChange = e => {
        setLoginData({ ...LoginData, [e.target.name]: e.target.value });
    };
    const handleLogin = async (e) => {
        e.preventDefault();
        setIsLoading(true);
        try {
            const response = await axios.post(`${API_URL}/users/login`, LoginData, { withCredentials: true });
            const { data } = response;
            if (data) {
                dispatch(setIsLogged(true));
                dispatch(setUserDetails(data));
                setIsLoading(false);
            }
        } catch (error) {
            setIsLoading(false);
            setErrorMessage("Email or Password is invalid");
            setTimeout(() => {
                setErrorMessage();
            }, 3000);
        }
    }
    // const handleGoogleLogin = () => {
    //     window.open(`${API_URL}/users/googleLogin`, "myWindowName", "width=600,height=400");
    // }
    const handleFacebookLogin = () => {
        window.open(`${API_URL}/users/facebookLogin`, "myWindowName", "width=600,height=400");
    }
    return (
        <div className='row d-flex justify-content-center'>
            <div className="col-9">
                <h2 className='text-center'>Login</h2>
                <div className='d-grid gap-2 mb-2'>
                    <a href={`${API_URL}/users/googleLogin`} className='btn btn-outline-secondary btn-block btn-social'>
                        <div className='d-flex justify-content-center align-items-center'>
                            <img src='/images/google_icon.svg' alt='google' className='wallet-icon me-1' />
                            <p className='m-0 text-dark'>Sign In with Google</p>
                        </div>
                    </a>
                </div>
                {/* <div className='d-grid gap-2 mb-2'>
                    <button onClick={handleFacebookLogin} className='btn btn-outline-secondary btn-block btn-social'>
                        <div className='d-flex justify-content-center align-items-center'>
                            <img src='/images/facebook_icon.svg' alt='facebook' className='wallet-icon me-1' />
                            <p className='m-0 text-dark'>Sign up with Facebook</p>
                        </div>
                    </button>
                </div> */}
                <p className='text-center'>OR</p>
                <div className='d-flex justify-content-center'>
                    {IsLoading && <Spinner animation="border" variant="dark" />}
                </div>
                <div className='d-flex justify-content-center'>
                    {errorMessage && <div className="alert alert-danger" role="alert">{errorMessage}</div>}
                </div>
                <form onSubmit={handleLogin}>
                    <div className="mb-2">
                        <input type="email" name='email' value={email} onChange={onInputChange} className='form-control' placeholder='Enter your email' />
                    </div>
                    <div className="mb-2">
                        <input type="password" name='password' value={password} onChange={onInputChange} className='form-control' placeholder='*******' />
                    </div>
                    <p className='text-end'>
                        <Link className='text-dark text-decoration-none'>Forgot password?</Link>
                    </p>
                    <div className="mb-2 d-flex justify-content-center">
                        <button type='submit' className='btn btn-warning'>Login</button>
                    </div>
                    <p className='text-center mt-2'>
                        Don't have an account?<button className='text-dark btn btn-transparent text-decoration-none fw-bolder' onClick={() => setShowSignUp()}>Create One</button>
                    </p>
                </form>
            </div>
        </div>
    )
}
