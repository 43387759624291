import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom';

import axios from 'axios';
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import { useSelector } from 'react-redux';
import { formatAsBrazilianCurrency, brazilCurrencyConvert } from '../../utils/Config'
import ProductCardPlaceholder from '../../components/search/ProductCardPlaceholder';
import SearchBox from '../../components/search/SearchBox';



export default function Main() {
    const API_URL = process.env.REACT_APP_API_URL;
    const user = useSelector((state) => state.user);
    const [searches, setSearches] = useState([]);
    const [SearchProducts, setSearchProducts] = useState([]);
    const [isLoading, setIsLoading] = useState(false);

    const getSearches = async () => {
        const response = await axios.get(`${API_URL}/search/latest`, { withCredentials: true })
        if (response) {
            setSearches(response.data)
        }
    }
    const LastSearchProducts = async (keywords) => {
        try {
            setIsLoading(true)
            let availableProducts = [];
            for (let i = 0; i < keywords.length; i++) {
                const response = await axios.get(`${process.env.REACT_APP_API_URL}/products/search?keyword=${keywords[i]}`, { withCredentials: true })
                if (response && response.data) {
                    availableProducts = response.data.filter(product => product.available);
                    if (availableProducts.length > 0) {
                        break;
                    }
                }
            }
            setSearchProducts(availableProducts);
            setIsLoading(false)
        } catch (error) {
            console.log(error)
        }
    }
    useEffect(() => {
        getSearches()
        //eslint-disable-next-line
    }, []);

    useEffect(() => {
        if (searches.length > 0) {
            LastSearchProducts(searches)
        }
    }, [searches]);

    const responsive = {
        superLargeDesktop: {
            // the naming can be any, depends on you.
            breakpoint: { max: 4000, min: 3000 },
            items: 5
        },
        desktop: {
            breakpoint: { max: 3000, min: 1024 },
            items: 4
        },
        tablet: {
            breakpoint: { max: 1024, min: 464 },
            items: 3
        },
        mobile: {
            breakpoint: { max: 464, min: 0 },
            items: 1
        }
    };
    return (
        <div>
            <div className="container py-5">
                <div className="row py-5 d-flex justify-content-center align-items-center" style={{ height: '79vh' }}>
                    <div className="col-md-6">
                        <div className="search">
                            <div className="row">
                                <div className="col-md-2"></div>
                                <div className="col-md-8"><Link to="/"> <img src='logo.png' alt='logo' className='img-fluid' /></Link></div>
                                <div className="col-md-2"></div>
                            </div>
                            <SearchBox />
                            {/* <div className="queries border rounded-2 py-1 px-2 mt-2">
                                <p className='mb-1 fw-bold'>Latest Search</p>
                                <div className="queries-list d-flex mb-2">
                                    {searches && searches.length > 0 && searches.map((query, index) => (
                                        <Link to={`/result?keyword=${query}`} className="btn btn-warning btn-sm me-1" key={index}>{query}</Link>
                                    ))}
                                </div>
                            </div> */}
                        </div>
                    </div>
                    <div className="row mt-3">
                        <Carousel
                            swipeable={true}
                            draggable={true}
                            showDots={true}
                            responsive={responsive}
                            ssr={true} // means to render carousel on server-side.
                            infinite={true}
                            autoPlay={true}
                            autoPlaySpeed={2000}
                            keyBoardControl={true}
                            customTransition="all .5"
                            transitionDuration={500}
                            containerClass="carousel-container"
                            removeArrowOnDeviceType={["tablet", "mobile"]}
                            dotListClass="custom-dot-list-style"
                            itemClass="carousel-item-padding-40-px p-2"
                        >
                            {isLoading && Array.from({ length: 5 }, (_, index) => <ProductCardPlaceholder key={index} />)}
                            {SearchProducts && SearchProducts.length > 0 && SearchProducts.map((item, index) => (
                                <Link
                                    key={index}
                                    to={{
                                        pathname: `/product/${item.reference}/${item?.name
                                            .replace(/[^a-zA-Z0-9 ]/g, "")
                                            .replaceAll(" ", "-")}`,
                                    }}
                                    className='product-link'
                                    state={{ item: item }}
                                >
                                    <div class="card w-100 m-2">
                                        <div className='card-img'><img src={item?.image} alt={item?.name} /></div>
                                        <div class="card-body">
                                            {item?.name?.length > 25 ? (
                                                <strong>{item?.name?.substring(0, 25)}...</strong>
                                            ) : (
                                                <strong>{item?.name}</strong>
                                            )}

                                            <div className="d-flex justify-content-between">
                                                <div className="price"><span class="badge text-bg-primary"> {`${user?.currency === 'BRL' ? `${formatAsBrazilianCurrency(brazilCurrencyConvert((item?.price / 100), user.currencyRate.BRL).toFixed(2))}` : `€${(item?.price / 100).toFixed(2)}`}`}</span></div>
                                                <div className="company">
                                                    <img src={item?.companyLogo} alt={item?.company} style={{ height: '25px' }} />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </Link>
                            ))
                            }
                        </Carousel>
                    </div>
                </div>
            </div >
        </div >
    )
}
