import React from 'react'
import { useEffect } from 'react';
import { useState } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { AiOutlineMinus, AiOutlinePlus } from 'react-icons/ai'
import axios from 'axios';
import { useDispatch, useSelector } from 'react-redux';
import { setCart, setShowCart } from '../../redux/userSlice';
import { Spinner } from 'react-bootstrap';
import { formatAsBrazilianCurrency, brazilCurrencyConvert } from '../../utils/Config'

export default function ProductDetails(props) {
    //const item = props.location.state;
    const params = useParams();
    const ref = params.ref
    //const item = location.state.item;
    const [item, setItem] = useState();
    const [currentImage, setCurrentImage] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const [qty, setQty] = useState(1);
    const cartDetails = useSelector((state) => state.user?.cart);
    const dispatch = useDispatch()
    const user = useSelector((state) => state.user);
    const GetProducts = async (ref) => {
        try {
            const response = await axios.get(`${process.env.REACT_APP_API_URL}/products/ref?keyword=${ref}`, { withCredentials: true })
            if (response) {
                let data = response.data
                data = data.filter(function (product) {
                    return product?.reference === ref;
                });
                setItem(data[0])
                if (data.length === 0) {
                    navigate('/404')
                }
            }
        } catch (error) {
            console.log(error)
            navigate('/404')
        }
    }

    useEffect(() => {
        if (ref) {
            GetProducts(ref)
        }
        //eslint-disable-next-line
    }, [ref]);

    useEffect(() => {
        if (item) {
            setCurrentImage(item?.image)
            document.title = `Miniaturizando: ${item?.name}`
        }
    }, [item]);

    function incrementQty() {
        if (qty < 10) {
            setQty(qty + 1);
        }
    }

    function decrementQty() {
        if (qty > 1) {
            setQty(qty - 1);
        }
    }
    const handleAddToCart = () => {
        setIsLoading(true);
        // Check if product is already in cart
        const existingCartItem = cartDetails.find(
            (cart) => cart?.product?.reference === item.reference
        );
        console.log(existingCartItem)
        if (existingCartItem) {
            // If product is already in cart, update its quantity
            const updatedCart = cartDetails.map((cartItem) => {
                if (cartItem?.product?.reference === existingCartItem?.product?.reference) {
                    return {
                        ...cartItem,
                        qty: cartItem?.qty + qty,
                    };
                } else {
                    return cartItem;
                }
            });
            dispatch(setCart(updatedCart));
        } else {
            // If product is not in cart, add it to the cart
            dispatch(setCart([...cartDetails, { qty: qty, product: item }]));
        }
        dispatch(setShowCart(true))
        setIsLoading(false);
    };
    const navigate = useNavigate();
    function handleBackClick() {
        navigate(-1);
    }

    return (
        <div className='product-details' style={{ minHeight: '88vh' }}>
            <div className='container details'>
                <div className="row p-3">
                    <div className="col-md-8">
                        <Link className='btn btn-warning' onClick={handleBackClick}>Go Back</Link>
                        <h3 className='mt-3'>{item?.name}</h3>
                        <div className="card shadow preview">
                            <img src={currentImage} alt={item?.name} className="img-fluid" />
                        </div>
                        <div className="list-of-images">
                            <div className="row d-flex justify-content-center">
                                {item?.listOfImages && item?.listOfImages.length > 0 && item?.listOfImages.map((img, index) => (
                                    <div className="col-md-3" key={index}>
                                        <div className='shadow border m-2'>
                                            <img src={img} alt={item?.name} onClick={() => setCurrentImage(img)} className="img-fluid gallery-item" />
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>
                    <div className="col-md-4 ps-4">
                        <div className='company  mt-5 mb-3'>
                            {item?.companyLogo && <img src={item?.companyLogo} alt={item?.company || 'logo'} className='img-fluid' style={{ height: '80px' }} />}
                        </div>
                        <div className="price">
                            <h2 className='text-success fw-bolder'> {`${user.currency === 'BRL' ? `${formatAsBrazilianCurrency(brazilCurrencyConvert((item?.price / 100), user.currencyRate.BRL).toFixed(2))}` : `€${(item?.price / 100).toFixed(2)}`}`}</h2>
                            <p className='text-uppercase fw-bold'>Incl. VAT</p>
                        </div>
                        <div className="add-to-cart row">
                            {isLoading && <Spinner animation="grow" />}
                            <div className="col-md-4">
                                <div className="qty">
                                    <div class="input-group mb-3">
                                        <div class="input-group-prepend">
                                            <button class="btn btn-dark btn-sm" id="minus-btn" onClick={decrementQty} disabled={!item?.available}><AiOutlineMinus /></button>
                                        </div>
                                        <input type="text" id="qty_input" class="form-control form-control-sm" value={qty} onChange={e => e.target.value <= 10 && setQty(e.target.value)} min="1" max="10" />
                                        <div class="input-group-prepend">
                                            <button class="btn btn-dark btn-sm" id="plus-btn" onClick={incrementQty} disabled={!item?.available}><AiOutlinePlus /></button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-6"><button className='btn btn-warning' onClick={handleAddToCart} disabled={!item?.available}>Add to Cart</button></div>
                        </div>
                        <div className='mb-3'>
                            <p className='text-uppercase mb-0 fw-bold'>Stock</p>
                            {item?.available ? <span className='badge text-bg-success ms-2'>Available</span> : <span className='badge text-bg-danger text-capitalize ms-2'>Out of Stock</span>}
                        </div>
                        <div className='mb-3'>
                            <p className='text-uppercase mb-0 fw-bold'>ART. NR.</p>
                            <p>{item?.reference}</p>
                        </div>
                        <div className='mb-3'>
                            <p className='text-uppercase mb-0 fw-bold'>Type of Edition</p>
                            <p>{item?.assortment}</p>
                        </div>
                        <div className='mb-3'>
                            <p className='text-uppercase mb-0 fw-bold'>brand</p>
                            <p>{item?.brand}</p>
                        </div>
                        <div className='mb-3'>
                            <p className='text-uppercase mb-0 fw-bold'>scale</p>
                            <p>{item?.scale}</p>
                        </div>
                        <div className='mb-3'>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
