import axios from 'axios';
import { useDispatch } from 'react-redux';
import { setIsLogged, setUserDetails } from '../../redux/userSlice';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

export default function AuthAccess() {
    const dispatch = useDispatch();
    const navigate = useNavigate()

    const fetchMe = async () => {
        try {
            const response = await axios.get(`${process.env.REACT_APP_API_URL}/users/me`, { withCredentials: true });
            if (response) {
                dispatch(setIsLogged(true));
                dispatch(setUserDetails(response.data));
                const lastRoute = localStorage.getItem('lastRoute');
                if (lastRoute) {
                    localStorage.removeItem('lastRoute');
                    navigate(lastRoute);
                } else {
                    navigate('/');
                }
            }
        } catch (error) {
            console.log(error);
        }
    };

    useEffect(() => {
        fetchMe();
        //eslint-disable-next-line
    }, []);

    return <div></div>;
}