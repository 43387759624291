import { configureStore, combineReducers } from '@reduxjs/toolkit';
import { encryptTransform } from 'redux-persist-transform-encrypt';
import { persistStore, persistReducer } from 'redux-persist';
import thunk from 'redux-thunk';
import storage from 'redux-persist/lib/storage';
import expireReducer from 'redux-persist-expire';
import { createStateSyncMiddleware, initMessageListener } from 'redux-state-sync';
import userSlice from './userSlice';

const encryptor = encryptTransform({
    secretKey: process.env.REACT_APP_REDUX_STORAGE_SECRET_KEY,
    onError: function (error) {
        console.log(error);
    },
});

const userReducer = combineReducers({
    user: userSlice,
});

const persistConfig = {
    key: 'root',
    storage: storage,
    timeout: null,
    transforms: [encryptor],
};

expireReducer('root', {
    //expireSeconds: 3600
    expireSeconds: 3000,
});

const persistedReducer = persistReducer(persistConfig, userReducer);

const syncMiddleware = createStateSyncMiddleware({
    blacklist: ['persist/PERSIST', 'persist/REHYDRATE'],
});

export const store = configureStore({
    reducer: persistedReducer,
    middleware: [syncMiddleware, thunk],
});

initMessageListener(store);

export const persistor = persistStore(store);