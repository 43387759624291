import axios from 'axios';
import React, { useState, useEffect } from 'react'
import { Spinner } from 'react-bootstrap';
import Modal from 'react-bootstrap/Modal';

export default function AddShippingAddressModal(props) {
    const API_URL = process.env.REACT_APP_API_URL;
    const [isLoading, setIsLoading] = useState(false);
    const [SuccessMessage, setSuccessMessage] = useState();
    const [countries, setCountries] = useState([]);
    const getCountries = async () => {
        const response = await axios.get(`https://countriesnow.space/api/v0.1/countries/flag/images`)
        if (response) {
            setCountries(response?.data?.data)
        }
    }
    useEffect(() => {
        getCountries()
    }, []);
    const [Data, setData] = useState({
        name: "",
        company: "",
        country: "",
        state: "",
        address1: "",
        address2: "",
        zip: "",
        phone: "",
        type: "shipping"
    });
    const onInputChange = e => {
        setData({ ...Data, [e.target.name]: e.target.value });
    };
    const handleSubmit = async (e) => {
        e.preventDefault();
        setIsLoading(true);
        try {
            const response = await axios.post(`${API_URL}/address`, Data, { withCredentials: true });
            if (response) {
                setIsLoading(false);
                props.GetBillingAddress()
                setData({
                    name: "",
                    company: "",
                    country: "",
                    state: "",
                    address1: "",
                    address2: "",
                    zip: "",
                    phone: "",
                    type: "shipping"
                })
                setSuccessMessage('Shipping Address Created Success.')
                setTimeout(() => {
                    setSuccessMessage();
                    props.setModalShow(false)
                }, 3000)
            }
        } catch (error) {
            console.log(error)
        }
    }
    return (
        <Modal
            {...props}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >
            <Modal.Body>
                <Modal.Header className='mb-2' closeButton>
                    <h4 className='text-center'>Add Shipping Address</h4>
                </Modal.Header>
                <div className='d-flex justify-content-center'>
                    {isLoading && <Spinner animation="border" variant="dark" />}
                </div>
                {SuccessMessage && <div className="alert alert-success" role="alert">{SuccessMessage} </div>}
                <form onSubmit={handleSubmit}>
                    <div className="row">
                        <div className="col-md-6">
                            <div className="mb-3">
                                <label htmlFor="name" className="form-label">Name</label>
                                <input type="text" className="form-control" name='name' onChange={onInputChange} id="name" placeholder="Enter full name" />
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="mb-3">
                                <label htmlFor="company" className="form-label">Company Name (optional)</label>
                                <input type="text" className="form-control" name='company' onChange={onInputChange} id="company" placeholder="Enter company name" />
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-6">
                            <div className="mb-3">
                                <label htmlFor="country" className="form-label">Country</label>
                                <select className="form-select" aria-label="Select country" name='country' onChange={onInputChange}>
                                    <option>Select Country</option>
                                    {countries && countries.length > 0 && countries.sort().map((item, index) => (
                                        <option value={item?.name} key={index}>{item?.name}</option>
                                    ))}
                                </select>
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="mb-3">
                                <label htmlFor="state" className="form-label">State</label>
                                <input type="text" className="form-control" name='state' onChange={onInputChange} id="state" placeholder="Enter state" />
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <label htmlFor="name" className="form-label">Street address</label>
                        <div className="col-md-6">
                            <div className="mb-3">
                                <input type="text" className="form-control" name='address1' onChange={onInputChange} id="address1" placeholder="House number and street name" />
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="mb-3">
                                <input type="text" className="form-control" name='address2' onChange={onInputChange} id="address2" placeholder="Apartment, suite, unit, etc. (optional)" />
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-6">
                            <div className="mb-3">
                                <label htmlFor="name" className="form-label">Zip Code</label>
                                <input type="text" className="form-control" name='zip' onChange={onInputChange} id="zip" placeholder="Zip Code" />
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="mb-3">
                                <label htmlFor="name" className="form-label">Phone</label>
                                <input type="text" className="form-control" name='phone' onChange={onInputChange} id="phone" placeholder="Enter phone" />
                            </div>
                        </div>
                    </div>
                    <div className="row justify-content-center">
                        <div className="col-md-4 d-flex justify-content-center">
                            <button type='submit' className='btn btn-warning'>Add Address</button>
                        </div>
                    </div>
                </form>
            </Modal.Body>
        </Modal>
    )
}
