import React from 'react'
import { useState } from 'react';
import { useEffect } from 'react';
import { AiOutlineMinus, AiOutlinePlus } from 'react-icons/ai'
import { MdOutlineDelete } from 'react-icons/md'
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { setCart, setShipping } from '../../redux/userSlice';
import { formatAsBrazilianCurrency, brazilCurrencyConvert } from '../../utils/Config'

export default function Cart() {
    const dispatch = useDispatch()
    const cart = useSelector((state) => state.user?.cart);
    const shipping = useSelector((state) => state.user?.shipping);
    const user = useSelector((state) => state.user);
    const [selectedOption, setSelectedOption] = useState("flat");
    const handleOptionChange = (event) => {
        setSelectedOption(event.target.value);
        event.target.value === "flat" ? dispatch(setShipping(20)) : dispatch(setShipping(0))

    };
    const AddItemQty = (cartItemToAdd) => {
        if (!cartItemToAdd) {
            return;
        }
        const { product: { reference }, qty } = cartItemToAdd;
        const existingItem = cart.find(item => item.product.reference === reference);

        if (existingItem) {
            const updatedCart = cart.map(item => {
                if (item.product.reference === reference) {
                    const newQty = qty + item.qty <= 10 ? qty + item.qty : item.qty;
                    return { ...item, qty: newQty };
                }
                return item;
            });

            dispatch(setCart(updatedCart));
        }
    };
    const RemoveItemQty = (cartItemToRemove) => {
        if (!cartItemToRemove) {
            return;
        }

        const { product: { reference } } = cartItemToRemove;
        const existingItem = cart.find(item => item.product.reference === reference);

        if (existingItem) {
            const updatedCart = cart.map(item => {
                if (item.product.reference === reference) {
                    const newQty = item.qty > 1 ? item.qty - 1 : item.qty;
                    return { ...item, qty: newQty };
                }
                return item;
            });

            dispatch(setCart(updatedCart));
        }
    };
    const removeItem = (itemToRemove) => {
        const updatedCart = cart.filter(
            (cartItem) => cartItem?.product?.reference !== itemToRemove?.product?.reference
        );
        dispatch(setCart(updatedCart));
    }
    const EmptyCart = () => {
        dispatch(setCart([]));
    }

    const [totalPrice, setTotalPrice] = useState(0);
    const [totalPriceBRL, setTotalPriceBRL] = useState(0);
    useEffect(() => {
        if (cart) {
            const cartSubtotals = cart.map((cartItem) => {
                const subtotal = cartItem?.product?.price * cartItem?.qty;
                return subtotal;
            });
            const cartSubtotalsBRL = cart.map((cartItem) => {
                const BRLPrice = brazilCurrencyConvert((cartItem?.product?.price / 100), user?.currencyRate?.BRL)
                const subtotal = BRLPrice * cartItem?.qty;
                return subtotal;
            });
            const cartTotal = cartSubtotals.reduce((acc, subtotal) => {
                return acc + subtotal;
            }, 0);
            const cartTotalBRL = cartSubtotalsBRL.reduce((acc, subtotal) => {
                return acc + subtotal;
            }, 0);
            setTotalPrice(cartTotal)
            setTotalPriceBRL(cartTotalBRL)
        }
    }, [cart, user]);
    useEffect(() => {
        document.title = `Miniaturizando: My Cart`
    }, []);
    return (
        <div className="cart">
            <div className='container' style={{ minHeight: '88vh' }}>
                <div className="row">
                    <div className="col-md-12">
                        <h3 className='text-center mt-4'>My Cart</h3>
                    </div>
                </div>
                <div className="row mt-5">
                    <div className="col-md-8">
                        <div className="row border-bottom">
                            <div className="col-md-5"><strong>Product</strong></div>
                            <div className="col-md-2"><strong>Price</strong></div>
                            <div className="col-md-2"><strong>Quantity</strong></div>
                            <div className="col-md-2"><strong>Subtotal</strong></div>
                            <div className="col-md-1"></div>
                        </div>
                        {cart && cart.length > 0 ? cart.map((item, index) => (
                            <div className="row my-2  d-flex align-items-center border-bottom">
                                <div className="col-md-5">
                                    <div className="row  d-flex align-items-center">
                                        <div className="col-md-4">
                                            <img src={item?.product?.image} alt={item?.product?.name} className="img-fluid" />
                                        </div>
                                        <div className="col-md-8">{item?.product?.name}</div>
                                    </div>
                                </div>
                                <div className="col-md-2">{`${user?.currency === 'BRL' ? `${formatAsBrazilianCurrency(brazilCurrencyConvert((item?.product?.price / 100), user.currencyRate.BRL).toFixed(2))}` : `€${(item?.product?.price / 100).toFixed(2)}`}`}</div>
                                <div className="col-md-2">
                                    <div className="qty">
                                        <div className="input-group mb-3">
                                            <div className="input-group-prepend">
                                                <button className="btn btn-dark btn-sm" id="minus-btn" onClick={() => RemoveItemQty(item)}><AiOutlineMinus /></button>
                                            </div>
                                            <input type="text" id="qty_input" className="form-control form-control-sm" value={item?.qty} min="1" max="10" />
                                            <div className="input-group-prepend">
                                                <button className="btn btn-dark btn-sm" id="plus-btn" onClick={() => AddItemQty(item)}><AiOutlinePlus /></button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-2">{`${user?.currency === 'BRL' ? `${formatAsBrazilianCurrency((brazilCurrencyConvert(((item?.product?.price) / 100), user.currencyRate.BRL) * item?.qty).toFixed(2))}` : `€${((item?.product?.price * item?.qty) / 100).toFixed(2)}`}`}</div>
                                <div className="col-md-1"><MdOutlineDelete onClick={() => removeItem(item)} className="fs-2 text-danger" /></div>
                            </div>
                        ))
                            :
                            <div className="row">
                                <div className="col-md-12">
                                    <p>Your cart is currently empty</p>
                                </div>
                            </div>
                        }
                        <div className="row">
                            <div className="col-md-6">

                            </div>
                            <div className="col-md-6 d-flex justify-content-end">
                                <button className='btn btn-outline-dark btn-rounded' onClick={() => EmptyCart()}> Empty Cart</button>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-4">
                        <div className="card shadow p-3">
                            <h3>Cart totals</h3>
                            <div className="row">
                                <div className="col-md-6">
                                    <strong>Subtotal</strong>
                                </div>
                                <div className="col-md-6 d-flex justify-content-end">
                                    <strong className='text-info'>{`${user?.currency === 'BRL' ? `${formatAsBrazilianCurrency(totalPriceBRL.toFixed(2))}` : `€${(totalPrice / 100).toFixed(2)}`}`}</strong>
                                </div>
                            </div>
                            <hr />
                            <div className="row">
                                <div className="col-md-6">
                                    <strong>Shipping</strong>
                                </div>
                                <div className="col-md-6 d-flex justify-content-end">
                                    <form>
                                        {user?.currency === 'BRL' ?
                                            <div className="form-check">
                                                <input
                                                    className="form-check-input"
                                                    type="radio"
                                                    value="free"
                                                    checked={true}
                                                    onChange={handleOptionChange}
                                                    name="shipping"
                                                    id="flat"
                                                />
                                                <label className="form-check-label" htmlFor="flat">
                                                    FREE
                                                </label>
                                            </div>
                                            :
                                            <>
                                                <div className="form-check">
                                                    <input
                                                        className="form-check-input"
                                                        type="radio"
                                                        value="flat"
                                                        checked={selectedOption === "flat"}
                                                        onChange={handleOptionChange}
                                                        name="shipping"
                                                        id="flat"
                                                    />
                                                    <label className="form-check-label" htmlFor="flat">
                                                        Postal Service: €20.00
                                                    </label>
                                                </div>
                                            </>
                                        }
                                    </form>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-md-6">
                                    <strong>Total</strong>
                                </div>
                                <div className="col-md-6 d-flex justify-content-end">
                                    <strong className='text-info'>{`${user?.currency === 'BRL' ? `${formatAsBrazilianCurrency(totalPriceBRL.toFixed(2))}` : `€${((totalPrice / 100) + shipping).toFixed(2)}`}`}</strong>
                                </div>
                            </div>
                            <div className="row d-flex justify-content-center mt-3">
                                <div className="col-md-7">
                                    <Link to="/checkout" className='btn btn-warning text-uppercase fw-bold'>Proceed to Checkout</Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
