import React from 'react'

export default function SearchPlaceholder() {
    return (
        <div className='card shadow mb-2 p-2 result'>
            <div className="row  align-items-center">
                <div className="col-md-2">
                    <img src="/placeholder-img.png" class="result-img" alt="placeholder" />
                </div>
                <div className="col-md-10">
                    <h3 class="card-title placeholder-glow">
                        <span class="placeholder col-6"></span>
                    </h3>
                    <p class="card-text placeholder-glow">
                        <span class="placeholder col-4"></span>
                        <span class="placeholder col-4"></span>
                        <span class="placeholder col-4"></span>
                    </p>
                </div>
            </div>
        </div>
    )
}
