import React from 'react'
import { Link } from 'react-router-dom'

export default function Footer() {
    return (
        <div className='container-fluid' id='footer'>
            <div className="container">
                <div className="row justify-content-center">
                    <div className="col-md-6 d-flex justify-content-center">
                        <ul className='nav'>
                            <li className='nav-link'><Link to="/privacy-policy">Privacy Policy</Link></li>
                            <li className='nav-link'><Link to="/terms-and-conditions">Terms and Conditions</Link></li>
                            <li className='nav-link'><Link to="/about-us">About Us</Link></li>
                            <li className='nav-link'><a href='mailto:miniaturizando@proton.me'>Contact Us</a></li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    )
}
