import React from 'react'
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { CiSearch } from 'react-icons/ci';
import Autosuggest from 'react-autosuggest';
// Documents to search amongst
const products = [
  { id: 1, name: 'Scania' },
  { id: 2, name: 'Volvo' },
  { id: 3, name: 'Mercedes' },
  { id: 4, name: 'White Line' },
  { id: 5, name: 'Torpedo' },
  { id: 6, name: 'Miniaturizando' },
  { id: 7, name: 'Mammoet' },
  { id: 8, name: 'Noteboom' },
  { id: 9, name: 'LTM' },
  { id: 10, name: 'Scania CS' },
]
export default function SearchBox() {
  const [value, setValue] = useState('');
  const [suggestions, setSuggestions] = useState([]);
  const navigate = useNavigate()
  const handleSubmit = async (e) => {
    e.preventDefault(); // Prevent default form submit behavior
    if (value) {
      navigate(`/result?keyword=${value}`);
    }
  }
  const getSuggestions = (value) => {
    const inputValue = value.trim().toLowerCase();
    const inputLength = inputValue.length;
    return inputLength === 0
      ? []
      : products.filter((product) =>
        product.name.toLowerCase().slice(0, inputLength) === inputValue
      );
  };

  const onSuggestionsFetchRequested = ({ value }) => {
    setSuggestions(getSuggestions(value));
  };

  const onSuggestionsClearRequested = () => {
    setSuggestions([]);
  };
  const onSuggestionSelected = async (event, { suggestion }) => {
    // Do something with the selected suggestion
    navigate(`/result?keyword=${suggestion?.name}`)
    // GetProducts(suggestion?.name)
  };

  const renderSuggestion = (suggestion) => (
    <div>
      <h2 className='suggestion-text'>{suggestion.name}</h2>
    </div>
  );

  const inputProps = {
    placeholder: 'e.g: scania, volvo, miniaturizando, scania torpedo, 04-2071',
    value,
    onChange: (event, { newValue }) => {
      setValue(newValue);
    },
    onKeyDown: (event) => {
      if (event.keyCode === 13) { // Enter key
        event.preventDefault(); // Prevent default form submit behavior
        navigate(`/result?keyword=${value}`);
      }
    },
  };
  return (
    <form className='' onSubmit={handleSubmit}>
      <div className="search mb-2">
        <div className="icon">
          <CiSearch className='fs-3' />
        </div>
        <Autosuggest
          suggestions={suggestions}
          className="form-control"
          onSuggestionsFetchRequested={onSuggestionsFetchRequested}
          onSuggestionsClearRequested={onSuggestionsClearRequested}
          getSuggestionValue={(suggestion) => suggestion.name}
          renderSuggestion={renderSuggestion}
          inputProps={inputProps}
          onSuggestionSelected={onSuggestionSelected}
        />
      </div>
      <div className='d-flex justify-content-center'>
        <button type='submit' className='btn btn-primary btn-search'>Search</button>
      </div>
    </form>
  )
}
