import { documentToHtmlString } from '@contentful/rich-text-html-renderer';
import React, { useState, useEffect } from 'react'
import { client } from '../../utils/Config';

export default function PrivacyPolicy() {
    const [content, setContent] = useState();
    useEffect(() => {
        document.title = `Miniaturizando: Privacy Policy`
        client.getEntries({ content_type: "privacyPolicy" })
            .then((response) => setContent(response.items[0].fields));
        // eslint-disable-next-line
    }, [])
    return (
        <div className='container' style={{ minHeight: '89vh' }}>
            <div className="row">
                <div className="col-md-12">
                    <h3 className='mt-4'>{content?.title}</h3>
                    <div className="description" dangerouslySetInnerHTML={{ __html: documentToHtmlString(content?.description) }}></div>
                </div>
            </div>
        </div >
    )
}
