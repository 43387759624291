import { createSlice } from '@reduxjs/toolkit'

export const userSlice = createSlice({
    name: 'user',
    initialState: {
        isLogged: false,
        details: {},
        cart: [],
        showCart: false,
        shipping: 20,
        currency: 'EUR',
        currencyRate: {},
        country: ''
    },
    reducers: {
        setIsLogged: (state, action) => {
            state.isLogged = action.payload
        },
        setUserDetails: (state, action) => {
            state.details = action.payload
        },
        setCart: (state, action) => {
            state.cart = action.payload
        },
        setShowCart: (state, action) => {
            state.showCart = action.payload
        },
        setShipping: (state, action) => {
            state.shipping = action.payload
        },
        setCurrency: (state, action) => {
            state.currency = action.payload
        },
        setCurrencyRate: (state, action) => {
            state.currencyRate = action.payload
        },
        setCountry: (state, action) => {
            state.country = action.payload
        }
    }
})

// Action creators are generated for each case reducer function
export const { setIsLogged, setUserDetails, setShipping, setShowCart, setCart, setCurrency, setCurrencyRate, setCountry } = userSlice.actions

export default userSlice.reducer