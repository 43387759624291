import React, { useState, useEffect } from 'react'
import { Link, useLocation } from 'react-router-dom'
import { BsCart4 } from 'react-icons/bs'
import { FiUsers } from 'react-icons/fi'
import Dropdown from 'react-bootstrap/Dropdown';
import { useDispatch, useSelector } from 'react-redux';
import { AiOutlineCloseCircle } from "react-icons/ai"
import { setCart, setIsLogged, setShowCart, setUserDetails } from '../../redux/userSlice';
import LoginCard from '../Login/LoginCard';
import SignUpCard from '../Login/SignUpCard';
import axios from 'axios';
import { Spinner } from 'react-bootstrap';
import { formatAsBrazilianCurrency, brazilCurrencyConvert } from '../../utils/Config'
import { useRef } from 'react';
export default function Header() {
    const dispatch = useDispatch()
    const ref = useRef()
    const [dropdownIsOpen, setDropdownIsOpen] = useState(false);
    const toggleDropdown = () => setDropdownIsOpen(!dropdownIsOpen)
    const location = useLocation();
    const cart = useSelector((state) => state.user?.cart);
    const showCart = useSelector((state) => state.user?.showCart);
    const isLogged = useSelector((state) => state.user?.isLogged);
    const user = useSelector((state) => state.user);
    const userDetails = useSelector((state) => state.user?.details);
    const [showDropdown, setShowDropdown] = useState(false);
    const [showLoading, setShowLoading] = useState(false);
    const [showSignUp, setShowSignUp] = useState(false);
    const [totalPrice, setTotalPrice] = useState(0);
    const [totalPriceBRL, setTotalPriceBRL] = useState(0);
    const [totalItems, setTotalItems] = useState(0);

    useEffect(() => {
        if (cart) {
            setShowLoading(true)
            const cartSubtotals = cart.map((cartItem) => {
                const subtotal = cartItem?.product?.price * cartItem?.qty;
                return subtotal;
            });
            const cartSubtotalsBRL = cart.map((cartItem) => {
                const BRLPrice = brazilCurrencyConvert((cartItem?.product?.price / 100), user?.currencyRate?.BRL)
                const subtotal = BRLPrice * cartItem?.qty;
                return subtotal;
            });
            const totalQty = cart.reduce((total, cartItem) => {
                return total + cartItem.qty;
            }, 0);
            setTotalItems(totalQty)
            const cartTotal = cartSubtotals.reduce((acc, subtotal) => {
                return acc + subtotal;
            }, 0);
            const cartTotalBRL = cartSubtotalsBRL.reduce((acc, subtotal) => {
                return acc + subtotal;
            }, 0);
            setTotalPrice(cartTotal)
            setTotalPriceBRL(cartTotalBRL)
            setTimeout(() => {
                setShowLoading(false)
            }, 500);
        }
    }, [cart, user]);

    useEffect(() => {
        if (showCart) {
            setShowDropdown(true)
        }
    }, [showCart]);

    const removeItem = (itemToRemove) => {
        const updatedCart = cart.filter(
            (cartItem) => cartItem?.product?.reference !== itemToRemove?.product?.reference
        );
        dispatch(setCart(updatedCart));
    }

    const handleLogout = async () => {
        try {
            const response = await axios.get(`${process.env.REACT_APP_API_URL}/users/logout`, { withCredentials: true })
            if (response) {
                dispatch(setIsLogged(false))
                dispatch(setUserDetails({}))
                window.localStorage.clear()
            } else {

            }
        } catch (error) {
            console.log(error)
        }
    }
    useEffect(() => {
        const checkIfClickedOutside = e => {
            // If the menu is open and the clicked target is not within the menu,
            // then close the menu
            if (dropdownIsOpen && ref.current && !ref.current.contains(e.target)) {
                setDropdownIsOpen(false)
            }
        }
        document.addEventListener("mousedown", checkIfClickedOutside)
        return () => {
            // Cleanup the event listener
            document.removeEventListener("mousedown", checkIfClickedOutside)
        }
    }, [dropdownIsOpen])
    return (
        <div className='container-fluid shadow-sm'>
            <div className="container">
                <div className="row py-2">
                    <div className="col-md-6">
                        {location.pathname !== '/' && <Link to="/"><img src='/logo.png' alt='logo' className='logo' /></Link>}
                    </div>
                    <div className="col-md-3">
                    </div>
                    <div className="col-md-3">
                        <ul className='nav algin-items-center'>
                            <li className='nav-item'>
                                <Link to="/products" className='nav-link text-black fs-5'>Products</Link>
                            </li>
                            <li className='nav-item'>
                                <Dropdown
                                    onToggle={() => dispatch(setShowCart(!showCart))}
                                    onMouseOver={() => setShowDropdown(true)}
                                    onMouseLeave={() => setShowDropdown(false)}
                                >
                                    <Dropdown.Toggle variant="transparent" id="dropdown-cart" className='cart'>
                                        {showLoading ?
                                            <div className='d-flex align-items-center'><Spinner /></div>
                                            :
                                            <div className='d-flex align-items-center'><BsCart4 className='fs-2' /><span className="cart-number">{totalItems || 0}</span></div>
                                        }
                                    </Dropdown.Toggle>
                                    <Dropdown.Menu show={showDropdown} className="cart-items">
                                        {cart && cart.length > 0 ? cart.map((item, index) => (
                                            <div className='dropdown-item' key={index}>
                                                <div className="row">
                                                    <div className="col-md-2 p-0">
                                                        <Link
                                                            to={{
                                                                pathname: `/product/${item?.product?.reference}/${item?.product?.name
                                                                    .replace(/[^a-zA-Z0-9 ]/g, "")
                                                                    .replaceAll(" ", "-")}`,
                                                            }}
                                                            className='product-link'
                                                            state={{ item: item }}
                                                        ><img src={item?.product?.image} alt={item?.product?.name} className="img-fluid" /></Link>

                                                    </div>
                                                    <div className="col-md-10">
                                                        <div className="row">
                                                            <div className="col-md-10"><Link
                                                                key={index}
                                                                to={{
                                                                    pathname: `/product/${item?.product?.reference}/${item?.product?.name
                                                                        .replace(/[^a-zA-Z0-9 ]/g, "")
                                                                        .replaceAll(" ", "-")}`,
                                                                }}
                                                                className='product-link'
                                                                state={{ item: item }}
                                                            ><strong className='text-wrap'>{item?.product?.name}</strong></Link></div>
                                                            <div className="col-md-1"><AiOutlineCloseCircle className='remove-item text-danger' onClick={() => removeItem(item)} /></div>
                                                        </div>
                                                        <div className='float-end text-success'>{item?.qty} x {`${user?.currency === 'BRL' ? `${formatAsBrazilianCurrency((brazilCurrencyConvert(((item?.product?.price) / 100), user.currencyRate.BRL) * item?.qty).toFixed(2))}` : `€${((item?.product?.price * item?.qty) / 100).toFixed(2)}`}`}</div>
                                                    </div>
                                                </div>
                                            </div>
                                        ))
                                            :
                                            <div className='dropdown-item'>Your cart is currently empty</div>
                                        }
                                        <hr />
                                        <div className="row p-3">
                                            <div className="col-md-6"><b>Sub-Total</b></div>
                                            <div className="col-md-6 d-flex justify-content-end"><strong className='text-info'>{`${user?.currency === 'BRL' ? `${formatAsBrazilianCurrency(totalPriceBRL.toFixed(2))}` : `€${(totalPrice / 100).toFixed(2)}`}`}</strong></div>
                                        </div>
                                        <div className="row mb-2">
                                            <div className="col-md-12 d-flex justify-content-center">
                                                <Link to="/cart" className='btn btn-warning'>View Cart</Link>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-md-12 d-flex justify-content-center">
                                                <Link to="/checkout" className='btn btn-info'>Checkout</Link>
                                            </div>
                                        </div>
                                    </Dropdown.Menu>
                                </Dropdown>
                            </li>
                            {isLogged ?
                                <li>
                                    <Dropdown
                                        show={dropdownIsOpen}
                                    >
                                        <Dropdown.Toggle variant="transparent" id="dropdown-user" className='cart' >
                                            <div className='d-flex align-items-center' onClick={(e) => toggleDropdown()}><img src={userDetails?.avatar} alt={userDetails?.name} className="profile-img" /></div>
                                        </Dropdown.Toggle>
                                        <Dropdown.Menu onClick={(e) => toggleDropdown()} ref={ref}>
                                            <Link to="/profile" className='dropdown-item'>Profile</Link>
                                            <Link to="/orders" className='dropdown-item'>Orders</Link>
                                            <Dropdown.Item href="#" onClick={() => handleLogout()}>Logout</Dropdown.Item>
                                        </Dropdown.Menu>
                                    </Dropdown>
                                </li>
                                :
                                <li>
                                    <Dropdown
                                        show={dropdownIsOpen}
                                    >
                                        <Dropdown.Toggle variant="transparent" id="dropdown-login" className='cart' onClick={(e) => toggleDropdown()}>
                                            <div className='d-flex align-items-center'><FiUsers className='fs-2' /></div>
                                        </Dropdown.Toggle>
                                        <Dropdown.Menu className="cart-items" onClick={(e) => toggleDropdown()} ref={ref}>
                                            {!showSignUp ?
                                                <LoginCard setShowSignUp={() => setShowSignUp(!showSignUp)} />
                                                :
                                                <SignUpCard setShowSignUp={() => setShowSignUp(!showSignUp)} />
                                            }
                                        </Dropdown.Menu>
                                    </Dropdown>
                                </li>
                            }
                        </ul>
                    </div>
                </div>
            </div>
        </div >
    )
}
