import Main from './pages/Main/Main';
import { BrowserRouter, Routes, Route, useLocation } from "react-router-dom";
import ProductDetails from './pages/Product/ProductDetails';
import Cart from './pages/Checkout/Cart';
import AuthAccess from './pages/Auth/AuthAccess';
import Footer from './components/layout/Footer';
import SearchResult from './pages/Main/SearchResult';
import Checkout from './pages/Checkout/Checkout';
import OrderSuccess from './pages/Order/OrderSuccess';
import Header from './components/layout/Header';
import PrivacyPolicy from './pages/PrivacyPolicy/PrivacyPolicy';
import TermsConditions from './pages/TermsConditions/TermsConditions';
import AboutUs from './pages/AboutUs/AboutUs';
import Profile from './pages/Profile/Profile';
import OrderHistory from './pages/Order/OrderHistory';
import OrderDetails from './pages/Order/OrderDetails';
import { useState, useEffect } from 'react';
import axios from 'axios';
import { useDispatch } from 'react-redux';
import { setCountry, setCurrency, setCurrencyRate, setShipping } from './redux/userSlice';
import './App.css';
import NotFound from './pages/NotFound/NotFound';
import Products from './pages/Product/Products';
import GoToTop from './components/common/GoToTop';
function App() {
  const dispatch = useDispatch()

  const [state, setState] = useState({
    ip: '',
    countryName: '',
    countryCode: '',
  });

  const { countryName } = state
  const getGeoInfo = () => {
    axios.get("https://ipapi.co/json/")
      .then((response) => {
        let data = response.data;
        setState({
          ...state,
          ip: data.ip,
          countryName: data.country_name,
          countryCode: data.country_code,
        });
        dispatch(setCountry(data.country_name))
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const getCurrency = () => {
    axios.get("https://min-api.cryptocompare.com/data/price?fsym=EUR&tsyms=BRL&api_key=98436b167303d66efa3bc1e3a9612f3e707db66194ae6dfe7c55c4be9bb54d1b")
      .then((response) => {
        let data = response.data;
        dispatch(setCurrency('BRL'))
        dispatch(setCurrencyRate(data))
        dispatch(setShipping(0))
      })
      .catch((error) => {
        console.log(error);
      });
  }

  useEffect(() => {
    if (countryName === "Brazil") {
      getCurrency()
    } else {
      dispatch(setCurrency('EUR'))
    }
  }, [countryName]);

  useEffect(() => {
    getGeoInfo();
  }, []);

  return (
    <BrowserRouter>
      <div className="App">
        <MainRoutes />
        <GoToTop />
      </div>
    </BrowserRouter>
  );
}

function MainRoutes() {
  const location = useLocation();
  useEffect(() => {
    if (location.pathname !== '/authAccess') {
      localStorage.setItem('lastRoute', location.pathname);
    }
  }, [location.pathname]);

  return (
    <>
      {location.pathname !== '/authAccess' && <Header />}
      <Routes>
        <Route path="/" exact element={<Main />} />
        <Route path="/result" exact element={<SearchResult />} />
        <Route path="/products" exact element={<Products />} />
        <Route path="/product/:ref/:name" exact element={<ProductDetails />} />
        <Route path="/cart" exact element={<Cart />} />
        <Route path="/checkout" exact element={<Checkout />} />
        <Route path="/order-success/:orderId" exact element={<OrderSuccess />} />
        <Route path="/authAccess" exact element={<AuthAccess />} />
        <Route path="/privacy-policy" exact element={<PrivacyPolicy />} />
        <Route path="/terms-and-conditions" exact element={<TermsConditions />} />
        <Route path="/about-us" exact element={<AboutUs />} />
        <Route path="/profile" exact element={<Profile />} />
        <Route path="/orders" exact element={<OrderHistory />} />
        <Route path="/order/:orderId" exact element={<OrderDetails />} />
        <Route path="*" element={<NotFound />} />
      </Routes>
      {location.pathname !== '/authAccess' && <Footer />}
    </>
  );
}

export default App;