import React from 'react'
import { useSelector } from 'react-redux';
import { formatAsBrazilianCurrency, brazilCurrencyConvert } from '../../utils/Config'

export default function ProductCard({ product }) {
    const user = useSelector((state) => state.user);
    return (
        <div className='card shadow'>
            <div className='card-img'><img src={product?.image} alt={product?.name} /></div>
            <div class="card-body">
                <h5 class="card-title product-title">{product?.name?.length > 45 ? (
                    <strong>{product?.name?.substring(0, 45)}...</strong>
                ) : (
                    <strong>{product?.name}</strong>
                )}</h5>
                <div className='d-flex justify-content-between'>
                    <div className="price"><span class="badge text-bg-primary"> {`${user?.currency === 'BRL' ? `${formatAsBrazilianCurrency(brazilCurrencyConvert((product?.price / 100), user.currencyRate.BRL).toFixed(2))}` : `€${(product?.price / 100).toFixed(2)}`}`}</span></div>
                    <div className="company"><img src={product?.companyLogo} alt={product?.company} style={{ height: '35px' }} /></div>
                </div>
            </div>
        </div>
    )
}
