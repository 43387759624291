import axios from 'axios';
import moment from 'moment';
import React, { useState, useEffect } from 'react'
import { Spinner } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import { formatAsBrazilianCurrency, brazilCurrencyConvert } from '../../utils/Config';

export default function OrderHistory() {
    const API_URL = process.env.REACT_APP_API_URL;
    const navigate = useNavigate()
    const [orders, setOrders] = useState([]);
    const [IsLoading, setIsLoading] = useState(false);
    const isLogged = useSelector((state) => state.user.isLogged) || '';
    useEffect(() => {
        if (isLogged) {
        } else {
            navigate('/')
        }
    }, [isLogged, navigate])
    const GetOrders = async () => {
        setIsLoading(true)
        const response = await axios.get(`${API_URL}/orders/me`, { withCredentials: true })
        if (response) {
            setOrders(response.data.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt)))
            setIsLoading(false)
        }
    }
    useEffect(() => {
        GetOrders();
        document.title = `Miniaturizando: Order History`
        //eslint-disable-next-line
    }, []);
    return (
        <div className='container' style={{ minHeight: '83vh' }}>
            <h3 className='mt-5 '>Order History</h3>
            <div className='d-flex justify-content-center'>
                {IsLoading && <Spinner animation="border" variant="dark" />}
            </div>
            {orders && orders.length > 0 ? orders.map((order, index) => (
                <div className='card shadow p-2 mb-3' key={index}>
                    <div className="card-header">
                        <div className="row">
                            <div className="col-md-6">
                                <div>
                                    <div><strong>Order</strong>: <Link to={`/order/` + order?._id} className="text-decoration-none text-info fw-bold">#{order?._id}</Link></div>
                                    <div><strong>Placed on</strong>: {moment(order?.createdAt).format("DD/MM/YYYY h:mm a")}</div>
                                    <div><strong>Payment Method</strong>: {(() => {
                                        switch (order?.payMethod) {
                                            case 'pix':
                                                return <img src='/images/pix.png' alt='pix' style={{ height: '30px' }} />
                                            case 'stripe':
                                                return <img src='/images/stripe.png' alt='stripe' style={{ height: '30px' }} />
                                            case 'paypal':
                                                return <img src='/images/paypal.png' alt='paypal' style={{ height: '30px' }} />
                                            default:
                                                return <img src='/images/pix.png' alt='pix' style={{ height: '30px' }} />
                                        }
                                    })()}</div>
                                </div>
                            </div>
                            <div className="col-md-6 d-flex justify-content-end">
                                <div className='float-end'>
                                    <div className=''> <Link to={`/order/` + order?._id} className="btn btn-warning text-uppercase btn-sm mb-2 text-decoration-none fw-bold">Details</Link></div>
                                    <div className="fw-bold">
                                        <strong>Status</strong>: {(() => {
                                            switch (order?.status) {
                                                case 'place':
                                                    return <span className='badge text-bg-primary text-capitalize ms-1'>{order?.status}</span>
                                                case 'pending':
                                                    return <span className='badge text-bg-warning text-capitalize ms-1'>{order?.status}</span>
                                                case 'failed':
                                                    return <span className='badge text-bg-danger text-capitalize ms-1'>{order?.status}</span>
                                                case 'paid':
                                                    return <span className='badge text-bg-success text-capitalize ms-1'>{order?.status}</span>
                                                case 'processing':
                                                    return <span className='badge text-bg-warning text-capitalize ms-1'>{order?.status}</span>
                                                case 'shipped':
                                                    return <span className='badge text-bg-pink text-capitalize ms-1'>{order?.status}</span>
                                                case 'delivered':
                                                    return <span className='badge text-bg-success text-capitalize ms-1'>{order?.status}</span>
                                                default:
                                                    return <span className='badge text-bg-primary text-capitalize ms-1'>{order?.status}</span>
                                            }
                                        })()}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="card-body">
                        <div className="row">
                            <div className="col-md-1"><strong>#</strong></div>
                            <div className="col-md-2"><strong>Image</strong></div>
                            <div className="col-md-4"><strong>Product</strong></div>
                            <div className="col-md-2"><strong>Price</strong></div>
                            <div className="col-md-1"><strong>Qty</strong></div>
                            <div className="col-md-2 text-end"><strong>Subtotal</strong></div>
                        </div>
                        {order?.products && order?.products.length > 0 && order?.products.map((item, index) => (
                            <div className="row" key={index}>
                                <div className="col-md-1">{index + 1}</div>
                                <div className="col-md-2"><img src={item?.productId.image} alt={item?.productId.name} className="img-fluid" /></div>
                                <div className="col-md-4">{item?.productId.name}</div>
                                <div className="col-md-2">{order?.currency === 'BRL' ? `${formatAsBrazilianCurrency(brazilCurrencyConvert((parseFloat(item?.productId.price) / 100), parseFloat(order?.currencyRate)).toFixed(2))}` : `€${(item?.productId.price / 100).toFixed(2)}`}</div>
                                <div className="col-md-1">{item?.qty}</div>
                                <div className="col-md-2 text-end">{order?.currency === 'BRL' ? `${formatAsBrazilianCurrency((brazilCurrencyConvert((parseInt(item?.productId.price / 100)), parseFloat(order?.currencyRate)) * item?.qty).toFixed(2))}` : `€${(parseInt(item?.productId.price / 100) * item?.qty).toFixed(2)}`}</div>
                            </div>
                        ))}

                    </div>
                    <div className="card-footer">
                        <div className="row">
                            <div className="col-md-8"></div>
                            <div className="col-md-2"><strong>Shipping ({order?.shipping === "flat" ? 'flat' : 'Pickup'})</strong></div>
                            <div className="col-md-2 text-end fw-bold">{order?.currency === 'BRL' ? 'FREE' : <>€{order?.shipping === "flat" ? '20.00' : '5.00'}</>}</div>
                        </div>
                        <div className="row">
                            <div className="col-md-8"></div>
                            <div className="col-md-2"><strong>Total</strong></div>
                            <div className="col-md-2 text-end fw-bold">{order?.currency === 'BRL' ? `${formatAsBrazilianCurrency(parseFloat(order?.amount).toFixed(2))}` : `€${order?.amount}`} </div>
                        </div>
                    </div>
                </div>
            ))
                :
                <>
                    No Order Found
                </>
            }
        </div>
    )
}
