import React, { useEffect } from 'react'
import { Link } from 'react-router-dom'
import './NotFound.css';
export default function NotFound() {
    useEffect(() => {
        document.title = `Miniaturizando: 404 Not Found`
    }, []);

    return (
        <div className="not-found-container">
            <div className="not-found">
                <h1 className="not-found__title">404 Page Not Found</h1>
                <p className="not-found__message">
                    The page you are looking for could not be found. Please check the URL and try again.
                </p>
                <Link to="/" className="not-found__link">
                    Go back to Home Page
                </Link>
            </div>
        </div>
    )
}
