import axios from 'axios';
import React, { useState, useEffect } from 'react'
import ReactPaginate from 'react-paginate';
import { Link } from 'react-router-dom';
import ProductCard from '../../components/Products/ProductCard';
import ProductCardPlaceholder from '../../components/search/ProductCardPlaceholder';

export default function Products() {
    const [isLoading, setIsLoading] = useState(false);
    const [products, setProducts] = useState([]);
    const [TotalProducts, setTotalProducts] = useState(0);
    const [offset, setOffset] = useState(0);
    const [perPage] = useState(12);
    const [pageCount, setPageCount] = useState(0);
    const GetProducts = async () => {
        try {
            setIsLoading(true)
            const response = await axios.get(`${process.env.REACT_APP_API_URL}/products`, { withCredentials: true })
            if (response) {
                const availableProducts = response?.data?.filter(product => product.available);
                setTotalProducts(availableProducts.length)
                setProducts(availableProducts.slice(offset, offset + perPage))
                setPageCount(
                    Math.ceil(availableProducts.length / perPage)
                );
                setIsLoading(false)
            }
        } catch (error) {
            console.log(error)
        }
    }
    useEffect(() => {
        GetProducts()
        //eslint-disable-next-line
    }, [offset]);
    const handlePageClick = (e) => {
        const selectedPage = e.selected;
        setOffset(selectedPage * perPage);
    };
    return (
        <div id='products'>
            <div className="container py-5" style={{ minHeight: '88vh' }}>
                <div className="row mb-2">
                    <div className="col-md-12">
                        <h3 className='mb-1'>Products</h3>
                        <small>{TotalProducts} products found</small>
                    </div>
                </div>
                <div className="row row-cols-sm-1 row-cols-md-2 row-cols-lg-3 row-cols-xl-4">
                    {isLoading && Array.from({ length: 8 }, (_, index) => <div className='col p-2'><ProductCardPlaceholder key={index} /></div>
                    )}
                    {products.length > 0 &&
                        products.map((product) => (
                            <Link
                                key={product.id}
                                to={{
                                    pathname: `/product/${product.reference}/${product?.name
                                        .replace(/[^a-zA-Z0-9 ]/g, "")
                                        .replaceAll(" ", "-")}`,
                                }}
                                state={{ item: product }}
                                className='text-decoration-none mb-2'
                            >
                                <ProductCard product={product} />
                            </Link>
                        ))}
                </div>
                <div className="row">
                    <div className="col-md-12">
                        <div className='d-flex justify-content-center'>
                            {products.length > 3 && (
                                <ReactPaginate
                                    previousLabel={"prev"}
                                    nextLabel={"next"}
                                    breakLabel={"..."}
                                    breakClassName={"break-me"}
                                    pageCount={pageCount}
                                    marginPagesDisplayed={2}
                                    pageRangeDisplayed={5}
                                    onPageChange={handlePageClick}
                                    containerClassName={"pagination"}
                                    subContainerClassName={"pages pagination"}
                                    activeClassName={"active"}
                                />
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
