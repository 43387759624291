import axios from 'axios';
import React from 'react'
import { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import AddBillingAddressModal from '../../components/Checkout/AddBillingAddressModal';
import AddShippingAddressModal from '../../components/Checkout/AddShippingAddressModal';
import LoginCard from '../../components/Login/LoginCard';
import SignUpCard from '../../components/Login/SignUpCard';
import { setCart, setShipping } from '../../redux/userSlice';
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";
import StripePayment from '../../components/Checkout/StripePayment';
import { formatAsBrazilianCurrency, brazilCurrencyConvert } from '../../utils/Config'
const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_API_KEY);

export default function Checkout() {
    const API_URL = process.env.REACT_APP_API_URL;
    const [clientSecret, setClientSecret] = useState("");
    const dispatch = useDispatch();
    const navigate = useNavigate();
    //stripe states
    const [loading, setLoading] = useState(false);
    const user = useSelector((state) => state.user);
    const isLogged = useSelector((state) => state.user?.isLogged);
    const [SuccessMessage, setSuccessMessage] = useState();
    const [errorMessage, setErrorMessage] = useState();
    const [showSignUp, setShowSignUp] = useState(false);
    const [BillingAddressModalShow, setBillingAddressModalShow] = useState(false);
    const [shippingAddressModalShow, setShippingAddressModalShow] = useState(false);
    const [showShippingAddress, setShowShippingAddress] = useState(false);
    const [billingAddress, setBillingAddress] = useState([]);
    const [shippingAddress, setShippingAddress] = useState([]);
    // Shipping Related States start
    const shipping = parseInt(useSelector((state) => state.user?.shipping) || 0);
    const [selectedOption, setSelectedOption] = useState("flat");
    const handleOptionChange = (event) => {
        setSelectedOption(event.target.value);
        event.target.value === "flat" ? dispatch(setShipping(20)) : dispatch(setShipping(0))
        handleChange(event.target.value, 'shipping')
    };
    // Shipping Related States end

    // Pay Method Related States start
    const [payMethod, setPayMethod] = useState("pix");
    const handlePayOptionChange = (event) => {
        setPayMethod(event.target.value);
        handleChange(event.target.value, 'payMethod')
    };
    // Pay Method Related States end
    const GetBillingAddress = async () => {
        const response = await axios.get(`${API_URL}/address/me`, { withCredentials: true })
        if (response) {
            const filteredBillingAddress = response?.data.filter((item) => item.type === "billing")
            const filteredShippingAddress = response?.data.filter((item) => item.type === "shipping")
            setBillingAddress(filteredBillingAddress)
            setShippingAddress(filteredShippingAddress)
        }
    }
    useEffect(() => {
        GetBillingAddress();
        document.title = `Miniaturizando: Checkout`
        //eslint-disable-next-line
    }, []);
    // Cart Related States start
    const cart = useSelector((state) => state.user?.cart);
    const [subTotalPrice, setSubTotalPrice] = useState(0);
    const [totalPrice, setTotalPrice] = useState(0);
    const [totalPriceBRL, setTotalPriceBRL] = useState(0);
    const [orderData, setOrderData] = useState({
        billingAddress: null,
        shippingAddress: null,
        shipping: selectedOption,
        payMethod: payMethod,
        showShipping: showShippingAddress,
        products: [],
        currency: 'eur',
        currencyRate: 0,
        amount: totalPriceBRL,
        agree: false
    });

    const handleChange = (value, name) => {
        setOrderData({
            ...orderData, [name]: value
        });
    }
    useEffect(() => {
        if (cart) {
            const cartSubtotals = cart.map((cartItem) => {
                const subtotal = cartItem?.product?.price * cartItem?.qty;
                return subtotal;
            });
            const cartSubtotalsBRL = cart.map((cartItem) => {
                const BRLPrice = brazilCurrencyConvert((cartItem?.product?.price / 100), user?.currencyRate?.BRL)
                const subtotal = BRLPrice * cartItem?.qty;
                return subtotal;
            });
            const cartTotal = cartSubtotals.reduce((acc, subtotal) => {
                return acc + subtotal;
            }, 0);
            const cartTotalBRL = cartSubtotalsBRL.reduce((acc, subtotal) => {
                return acc + subtotal;
            }, 0);
            setSubTotalPrice(cartTotal / 100)
            const totalAmount = ((cartTotal / 100) + shipping).toFixed(2)
            setTotalPrice(totalAmount)
            setTotalPriceBRL(cartTotalBRL)
            const newCartArray = cart.map((item) => {
                return {
                    productId: item.product._id,
                    qty: item.qty
                }
            });
            handleChange(newCartArray, 'products')
        }
        //eslint-disable-next-line
    }, [cart, shipping]);

    useEffect(() => {
        if (totalPrice) {
            handleChange(totalPrice, 'amount')
        }
        if (totalPriceBRL) {
            handleChange(totalPriceBRL, 'amount')
        }
        //eslint-disable-next-line
    }, [totalPrice, totalPriceBRL]);
    useEffect(() => {
        if (showShippingAddress) {
            handleChange(showShippingAddress, 'showShipping')
        }
        //eslint-disable-next-line
    }, [showShippingAddress]);

    useEffect(() => {
        if (isLogged) {
            GetBillingAddress()
        }
        //eslint-disable-next-line
    }, [isLogged]);

    const handleOtherOrder = async (order) => {
        const orderData = { ...order, currency: 'BRL', currencyRate: user?.currencyRate?.BRL }
        try {
            setLoading(true)
            const response = await axios.post(`${API_URL}/orders`, orderData, { withCredentials: true });
            const { data } = response;
            if (data) {
                setSuccessMessage("Order placed successfully.")
                dispatch(setCart([]))
                setTimeout(() => {
                    setSuccessMessage();
                    navigate(`/order-success/${data?._id}`)
                    setLoading(false)
                }, 3000)
            }
        } catch (error) {
            alert('Order Failed');
        }
    };

    const handleOrderSubmit = async (e) => {
        e.preventDefault();
        let errorMessage = "";

        if (!orderData.billingAddress) {
            errorMessage += "Please enter a billing address.<br/>";
        }

        if (showShippingAddress && !orderData.shippingAddress) {
            errorMessage += "Please enter a shipping address.<br/>";
        }

        if (!orderData.agree) {
            errorMessage += "Please agree to the terms and conditions.<br/>";
        }

        if (!cart || cart.length === 0) {
            errorMessage += "Your cart is empty.\n";
        }

        if (errorMessage) {
            setErrorMessage(errorMessage);
            setTimeout(() => {
                setErrorMessage();
            }, 3000);
            return;
        }
        //handle order now
        switch (orderData.payMethod) {
            case "stripe":
                // Code to handle Stripe payment
                break;
            case "paypal":
                // Code to handle PayPal payment
                break;
            default:
                // Code to handle other payment types, such as cash on delivery or bank transfer
                handleOtherOrder(orderData);
        }

    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    useEffect(() => {
        async function fetchData() {
            if (totalPrice > 0) {
                const paymentInit = await axios.post(`${API_URL}/orders/stripe/paymentIntent`, { amount: totalPrice }, { withCredentials: true });
                if (paymentInit) {
                    setClientSecret(paymentInit.data.clientSecret)
                }
            }

        }
        fetchData();
        // Create PaymentIntent as soon as the page loads
        //eslint-disable-next-line
    }, [totalPrice]);
    const appearance = {
        theme: 'stripe',
    };
    const options = {
        clientSecret,
        appearance,
    };
    // Cart Related States end
    return (
        <div className='checkout'>
            <div className="container py-4" style={{ minHeight: '88vh' }}>
                <div className="row my-3">
                    <div className="col-md-12">
                        <h3 className='text-center'>Checkout</h3>
                    </div>
                </div>
                <div className="row algin-items-center">
                    <div className="col-md-7">
                        {!isLogged ?
                            <div className='card shadow p-3'>
                                {!showSignUp ?
                                    <LoginCard setShowSignUp={() => setShowSignUp(!showSignUp)} />
                                    :
                                    <SignUpCard setShowSignUp={() => setShowSignUp(!showSignUp)} />
                                }
                            </div>
                            :
                            <>
                                <div className="card shadow p-3">
                                    <h4>Billing Details</h4>
                                    {billingAddress && billingAddress.length > 0 ?
                                        <div className=''>
                                            <p>Please select a billing address</p>
                                            {billingAddress.map((address, index) => (
                                                <div className="address card px-3 py-2 mb-2" key={index}>
                                                    <div className="form-check mb-1">
                                                        <input className="form-check-input" type="radio" name="billing" onChange={e => handleChange(e.target.value, 'billingAddress')} value={address?._id} id={address?._id} />
                                                        <label className="form-check-label fw-bolder" htmlFor={address?._id}>
                                                            {address?.name}
                                                        </label>
                                                    </div>
                                                    <div className="">
                                                        {address?.address1},{address?.address2}
                                                    </div>
                                                    <div className="">
                                                        {address?.state},{address?.country}
                                                    </div>
                                                    <div className="">
                                                        {address?.phone}
                                                    </div>
                                                </div>
                                            ))}
                                            <div>
                                                <p>or add a new address</p>
                                                <button className='btn btn-warning' onClick={() => setBillingAddressModalShow(true)}>Add Billing Address</button>
                                            </div>
                                        </div>
                                        :
                                        <div>
                                            <p>You don't have any billing address, please add one</p>
                                            <button className='btn btn-warning' onClick={() => setBillingAddressModalShow(true)}>Add Billing Address</button>
                                        </div>
                                    }
                                    <div className="mt-2">
                                        <div className="form-check">
                                            <input className="form-check-input" type="checkbox" value="" id="shippingAddress" onChange={() => setShowShippingAddress(!showShippingAddress)} />
                                            <label className="form-check-label" htmlFor="shippingAddress">
                                                <strong> Ship to a different address?</strong>
                                            </label>
                                        </div>
                                    </div>
                                    {showShippingAddress &&
                                        <div className=''>
                                            {shippingAddress && shippingAddress.length > 0 ?
                                                <>
                                                    <h4>Shipping Details</h4>
                                                    <p>Please select a Shipping address</p>
                                                    {shippingAddress.map((address, index) => (
                                                        <div className="address card px-3 py-2 mb-2" key={index}>
                                                            <div className="form-check mb-1">
                                                                <input className="form-check-input" type="radio" name="shipping" onChange={e => handleChange(e.target.value, 'shippingAddress')} value={address?._id} id={address?._id} />
                                                                <label className="form-check-label fw-bolder" htmlFor={address?._id}>
                                                                    {address?.name}
                                                                </label>
                                                            </div>
                                                            <div className="">
                                                                {address?.address1}, {address?.address2}
                                                            </div>
                                                            <div className="">
                                                                {address?.state}, {address?.country}
                                                            </div>
                                                            <div className="">
                                                                {address?.phone}
                                                            </div>
                                                        </div>
                                                    ))}
                                                    <div>
                                                        <p>or add a new address</p>
                                                        <button className='btn btn-warning' onClick={() => setShippingAddressModalShow(true)}>Add Shipping Address</button>
                                                    </div>
                                                </>
                                                :
                                                <div>
                                                    <p>You don't have any Shipping address, please add one</p>
                                                    <button className='btn btn-warning' onClick={() => setShippingAddressModalShow(true)}>Add Shipping Address</button>
                                                </div>
                                            }
                                        </div>
                                    }
                                </div>
                            </>
                        }
                    </div>
                    <div className="col-md-5">
                        <div className="card p-3 shadow border-warning">
                            <h3>Your order</h3>
                            <div className="row">
                                <div className="col-md-8">
                                    <strong>Product</strong>
                                </div>
                                <div className="col-md-2 text-center">
                                    <strong className=''>Qty</strong>
                                </div>
                                <div className="col-md-2">
                                    <strong className='float-end'>Subtotal</strong>
                                </div>
                            </div>
                            <div className='products-cart mt-3'>
                                {cart && cart.length > 0 ? cart.map((item, index) => (
                                    <div className="row border-bottom" key={index}>
                                        <div className="col-md-8">
                                            <p className='text-wrap'>{item?.product?.name}</p>
                                        </div>
                                        <div className="col-md-2">
                                            <p className='text-center'>{item?.qty}</p>
                                        </div>
                                        <div className="col-md-2">
                                            <div className='float-end text-info'>{`${user?.currency === 'BRL' ? `${formatAsBrazilianCurrency((brazilCurrencyConvert(((item?.product?.price) / 100), user.currencyRate.BRL) * item?.qty).toFixed(2))}` : `€${((item?.product?.price * item?.qty) / 100).toFixed(2)}`}`}</div>
                                        </div>
                                    </div>
                                ))
                                    :
                                    <div>Your cart is currently empty</div>
                                }
                                <div className="row border-bottom">
                                    <div className="col-md-6">
                                        <strong>Subtotal</strong>
                                    </div>
                                    <div className="col-md-6">
                                        <p className='text-info float-end fw-bolder m-0 my-1'>{`${user?.currency === 'BRL' ? `${formatAsBrazilianCurrency(totalPriceBRL.toFixed(2))}` : `€${(subTotalPrice).toFixed(2)}`}`}</p>
                                    </div>
                                </div>
                                <div className="row border-bottom py-2">
                                    <div className="col-md-6">
                                        <strong>Shipping</strong>
                                    </div>
                                    <div className="col-md-6 d-flex justify-content-end">
                                        <form>
                                            <form>
                                                {user?.currency === 'BRL' ?
                                                    <div className="form-check">
                                                        <input
                                                            className="form-check-input"
                                                            type="radio"
                                                            value="free"
                                                            checked={true}
                                                            onChange={handleOptionChange}
                                                            name="shipping"
                                                            id="flat"
                                                        />
                                                        <label className="form-check-label" htmlFor="flat">
                                                            FREE
                                                        </label>
                                                    </div>
                                                    :
                                                    <>
                                                        <div className="form-check">
                                                            <input
                                                                className="form-check-input"
                                                                type="radio"
                                                                value="flat"
                                                                checked={selectedOption === "flat"}
                                                                onChange={handleOptionChange}
                                                                name="shipping"
                                                                id="flat"
                                                            />
                                                            <label className="form-check-label" htmlFor="flat">
                                                                Postal Service: €20.00
                                                            </label>
                                                        </div>
                                                    </>
                                                }
                                            </form>
                                        </form>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-md-6">
                                        <strong>Total</strong>
                                    </div>
                                    <div className="col-md-6">
                                        <p className='text-info float-end fw-bolder m-0 my-1'>{`${user?.currency === 'BRL' ? `${formatAsBrazilianCurrency(totalPriceBRL.toFixed(2))}` : `€${(subTotalPrice + shipping).toFixed(2)}`}`}</p>
                                    </div>
                                </div>
                                {isLogged &&
                                    <>
                                        <div className="toc mt-2">
                                            <div className="form-check">
                                                <input className="form-check-input" type="checkbox" value={orderData.agree} onChange={e => handleChange(!orderData.agree, 'agree')} name="toc" id="toc" />
                                                <label className="form-check-label" htmlFor="toc">
                                                    <i>I have read and agree to the website <a href={`/terms-and-conditions`} target="_blank" rel="noopener noreferrer" className="text-info">terms and conditions</a> <span className='text-danger'>*</span></i>
                                                </label>
                                            </div>
                                        </div>
                                        <div className="pay-methods mt-3">
                                            <strong>Payment Method</strong>
                                            {user?.currency === "BRL" ?
                                                <div className="pix mb-1">
                                                    <div className="form-check">
                                                        <input className="form-check-input" type="radio" value="pix" checked={payMethod === "pix"} onChange={handlePayOptionChange} name="payMethod" id="pix" />
                                                        <label className="form-check-label me-1" htmlFor="other">
                                                            <img src="/images/pix.png" alt="pix" className='pay-logo' />
                                                        </label>
                                                    </div>
                                                </div>
                                                :
                                                <div className="stripe my-2">
                                                    <div className="form-check">
                                                        <input className="form-check-input" type="radio" value="stripe" checked={payMethod === "stripe"} onChange={handlePayOptionChange} name="payMethod" id="stripe" />
                                                        <label className="form-check-label" htmlFor="stripe">
                                                            <img src="/images/stripe.png" alt="stripe" className='pay-logo' />
                                                        </label>
                                                    </div>
                                                    <div className="row">
                                                        <div className="col-md-12">
                                                            {payMethod === "stripe" &&
                                                                <>
                                                                    <div className="mb-3">
                                                                        <label htmlFor="card-element" className="form-label">
                                                                            Card Details
                                                                        </label>
                                                                    </div>
                                                                    {clientSecret && (
                                                                        <Elements options={options} stripe={stripePromise}>
                                                                            <StripePayment orderData={orderData} showShippingAddress={showShippingAddress} />
                                                                        </Elements>
                                                                    )}
                                                                </>
                                                            }
                                                        </div>
                                                    </div>
                                                </div>
                                            }
                                        </div>
                                        <div className='d-flex justify-content-center'>
                                            {errorMessage && <div className="alert alert-danger" role="alert" dangerouslySetInnerHTML={{ __html: errorMessage }}></div>}
                                        </div>
                                        <div className='d-flex justify-content-center'>
                                            {SuccessMessage && <div className="alert alert-success" role="alert" >{SuccessMessage}</div>}
                                        </div>
                                        {user?.currency === "BRL" &&
                                            <form onSubmit={handleOrderSubmit}>
                                                {payMethod === "pix" &&
                                                    <div className="action d-flex justify-content-center mt-3">
                                                        <button type='submit' className='btn btn-warning text-uppercase' disabled={!isLogged}> {loading ? 'Processing...' : 'Place Order'}</button>
                                                    </div>
                                                }
                                            </form>
                                        }
                                    </>
                                }

                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <AddBillingAddressModal
                show={BillingAddressModalShow}
                onHide={() => setBillingAddressModalShow(false)}
                setModalShow={setBillingAddressModalShow}
                GetBillingAddress={GetBillingAddress}
            />
            <AddShippingAddressModal
                show={shippingAddressModalShow}
                onHide={() => setShippingAddressModalShow(false)}
                setModalShow={setShippingAddressModalShow}
                GetBillingAddress={GetBillingAddress}
            />
        </div >
    )
}
