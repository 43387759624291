
import { createClient } from "contentful";

export const client = createClient({
    accessToken: process.env.REACT_APP_Contentful_API_Access_Token,
    environment: 'master',
    space: process.env.REACT_APP_Contentful_space,
})

export const brazilCurrencyConvert = (amount, rate) => {
    if (amount > 0) {
        const convertedAmount = (amount + 40) * rate;
        const percentageAmount = (convertedAmount * 30) / 100;
        return convertedAmount + percentageAmount;
    } else {
        return 0
    }
    // return Number(result.toFixed(2));
}

export function formatAsBrazilianCurrency(price) {
    const formatter = new Intl.NumberFormat('pt-BR', {
        style: 'currency',
        currency: 'BRL',
        minimumFractionDigits: 2,
        maximumFractionDigits: 2
    });
    return formatter.format(price);
}