import React, { useEffect, useState } from "react";
import { PaymentElement, useStripe, useElements } from "@stripe/react-stripe-js";
import axios from "axios";
import { useSelector } from "react-redux";
import { Spinner } from "react-bootstrap";

export default function StripePayment({ orderData, showShippingAddress }) {
    const stripe = useStripe();
    const elements = useElements();
    const API_URL = process.env.REACT_APP_API_URL;
    const cart = useSelector((state) => state.user?.cart);
    const [message, setMessage] = useState(null);
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        if (!stripe) {
            return;
        }

        const clientSecret = new URLSearchParams(window.location.search).get(
            "payment_intent_client_secret"
        );

        if (!clientSecret) {
            return;
        }

        stripe.retrievePaymentIntent(clientSecret).then(({ paymentIntent }) => {
            switch (paymentIntent.status) {
                case "succeeded":
                    setMessage("Payment succeeded!");
                    break;
                case "processing":
                    setMessage("Your payment is processing.");
                    break;
                case "requires_payment_method":
                    setMessage("Your payment was not successful, please try again.");
                    break;
                default:
                    setMessage("Something went wrong.");
                    break;
            }
        });
    }, [stripe]);

    const saveOrder = async (orderData) => {
        try {
            const StripeOrderData = { ...orderData }
            const response = await axios.post(`${API_URL}/orders`, StripeOrderData, { withCredentials: true });
            const { data } = response;
            return `${process.env.REACT_APP_WEBSITE_URL}/order-success/${data?._id}`
        } catch (error) {
            console.log(error)
        }
    }
    const handleSubmit = async (e) => {
        e.preventDefault();
        if (!stripe || !elements) {
            // Stripe.js has not yet loaded.
            // Make sure to disable form submission until Stripe.js has loaded.
            return;
        }
        let errorMessage = "";
        if (!orderData.billingAddress) {
            errorMessage += "Please enter a billing address.<br/>";
        }

        if (showShippingAddress && !orderData.shippingAddress) {
            errorMessage += "Please enter a shipping address.<br/>";
        }

        if (!orderData.agree) {
            errorMessage += "Please agree to the terms and conditions.<br/>";
        }

        if (!cart || cart.length === 0) {
            errorMessage += "Your cart is empty.\n";
        }

        if (errorMessage) {
            setMessage(errorMessage)
            setTimeout(() => {
                setMessage();
            }, 5000);
            return;
        } else {
            setIsLoading(true);
            const RedirectURL = await saveOrder(orderData)
            const { error } = await stripe.confirmPayment({
                elements,
                confirmParams: {
                    return_url: RedirectURL,
                },
            });
            if (error.type === "card_error" || error.type === "validation_error") {
                setMessage(error.message);
            } else {
                setMessage("An unexpected error occurred.");
            }
            setIsLoading(false);
        }
    };
    const paymentElementOptions = {
        layout: "auto"
    }
    return (
        <form id="payment-form" onSubmit={handleSubmit}>
            <PaymentElement id="payment-element" options={paymentElementOptions} />
            <div className="d-flex justify-content-center">
                <button disabled={isLoading || !stripe || !elements} id="submit" className="btn btn-warning my-2">
                    <span id="button-text">
                        {isLoading ? <div className="d-flex">Processing <Spinner animation="border" variant="dark" /></div> : "Pay now"}
                    </span>
                </button>
            </div>
            {/* Show any error or success messages */}
            {message && <div className="alert alert-danger" role="alert" dangerouslySetInnerHTML={{ __html: message }}></div>}
        </form>
    )
}
