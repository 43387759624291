import axios from 'axios';
import React, { useState, useEffect } from 'react'
import { Spinner } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { setUserDetails } from '../../redux/userSlice';

export default function Profile() {
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const userDetails = useSelector((state) => state.user?.details);
    const isLogged = useSelector((state) => state.user.isLogged) || '';
    useEffect(() => {
        if (isLogged) {
        } else {
            navigate('/')
        }
    }, [isLogged, navigate])
    const [SuccessMessage, setSuccessMessage] = useState();
    const [IsLoading, setIsLoading] = useState(false);
    const [profileData, setProfileData] = useState({
        name: "",
        email: "",
        phone: "",
    });
    const { name, email, phone } = profileData
    const onInputChange = e => {
        setProfileData({ ...profileData, [e.target.name]: e.target.value });
    };
    useEffect(() => {
        if (userDetails) {
            setProfileData({ name: userDetails.name, email: userDetails.email, phone: userDetails.phone, address: userDetails.address })
        }
    }, [userDetails]);
    const [selectedImage, setSelectedImage] = useState(null);
    const [imageUrl, setImageUrl] = useState(null);
    const handleFileUpload = file => {
        setImageUrl(URL.createObjectURL(file));
        let form = new FormData()
        form.append('avatar', file)
        setSelectedImage(form);
    }
    const submitHandler = async (e) => {
        e.preventDefault();
        setIsLoading(true)
        const response = await axios.put(`${process.env.REACT_APP_API_URL}/users/me`, profileData, { withCredentials: true });
        if (response) {
            if (selectedImage === null) {
                dispatch(setUserDetails(response.data));
                setIsLoading(false);
                setSuccessMessage("Your Profile data has been updated.");
                setTimeout(() => {
                    setSuccessMessage();
                }, 3000)
            } else {
                const addImageResponse = await axios.put(`${process.env.REACT_APP_API_URL}/users/me/avatarUpload`, selectedImage, { withCredentials: true })
                if (addImageResponse) {
                    dispatch(setUserDetails(addImageResponse.data));
                    setIsLoading(false);
                    setSuccessMessage("Your Profile data has been updated.");
                    setTimeout(() => {
                        setSuccessMessage();
                        setImageUrl();
                    }, 3000)
                }
            }
        }
    }
    useEffect(() => {
        document.title = "Miniaturizando: Profile"
        // eslint-disable-next-line
    }, []);
    return (
        <div className='container' style={{ minHeight: '83vh' }}>
            <form onSubmit={submitHandler}>
                <h3 className='mt-5 '>Update your profile</h3>
                <div className='d-flex justify-content-center'>
                    {IsLoading && <Spinner animation="border" variant="dark" />}
                </div>
                {SuccessMessage && <div className="alert alert-success" role="alert">{SuccessMessage} </div>}
                <div className="row py-5">

                    <div className="col-md-6">
                        <div className="mb-3">
                            <label htmlFor="name" className="form-label">Name</label>
                            <input type="text" className="form-control" value={name} name='name' onChange={onInputChange} id="name" placeholder="Enter full name" />
                        </div>
                        <div className="mb-3">
                            <label htmlFor="email" className="form-label">Email</label>
                            <input type="email" className="form-control" value={email} name='email' onChange={onInputChange} id="email" placeholder="Enter your email" disabled />
                        </div>
                        <div className="mb-3">
                            <label htmlFor="phone" className="form-label">Phone</label>
                            <input type="text" className="form-control" value={phone} name='phone' onChange={onInputChange} id="phone" placeholder="Enter your phone" />
                        </div>
                        <div className="mb-3">
                            <button className='btn btn-warning' type='submit'>Save</button>
                        </div>
                    </div>
                    <div className="col-md-6">
                        <div className="mb-3">
                            <label htmlFor="avatar" className="form-label">Profile Photo</label>
                            {imageUrl && selectedImage ? (
                                <div mt={2} textAlign="center">
                                    <div>Image Preview:</div>
                                    <img src={imageUrl} alt="avatar" height="200px" />
                                </div>
                            )
                                :
                                <div>
                                    <input className='form-control d-none' accept="image/*" type="file" id="select-image" onChange={e => handleFileUpload(e.target.files[0])} />
                                    <label htmlFor="select-image" className='upload'>
                                        {userDetails.avatar ?
                                            <img src={userDetails.avatar} alt="profile" height="200px" className='rounded-3' />
                                            :
                                            <img src="/images/upload.png" alt="avatar" height="200px" className='rounded-3' />
                                        }
                                    </label>
                                </div>
                            }
                        </div>
                    </div>
                </div>
            </form>
        </div>
    )
}
