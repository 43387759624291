import React from 'react'

export default function ProductCardPlaceholder() {
    return (
        <div className='card shadow'>
            <div className='card-img'><img src="/placeholder-img.png" alt="placeholder" /></div>
            <div class="card-body">
                <strong className='placeholder-glow'><span class="placeholder col-6"></span></strong>
                <div className="d-flex justify-content-between">
                    <div className="price placeholder col-6"></div>
                    <div className="company placeholder col-6">
                    </div>
                </div>
            </div>
        </div>
    )
}
