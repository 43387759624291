import axios from 'axios';
import React, { useState, useEffect } from 'react'
import { Link, useSearchParams } from 'react-router-dom';
import SearchPlaceholder from '../../components/search/SearchPlaceholder';
import { BsCheckCircleFill } from 'react-icons/bs'
import { RxCrossCircled } from 'react-icons/rx'
import { useSelector } from 'react-redux';
import { formatAsBrazilianCurrency, brazilCurrencyConvert } from '../../utils/Config'
import SearchBox from '../../components/search/SearchBox';

export default function SearchResult() {
    const [searchParams, setSearchParams] = useSearchParams();
    const user = useSelector((state) => state.user);
    const keyword = searchParams.get("keyword")
    const [isLoading, setIsLoading] = useState(false);
    const [showNotFound, setShowNotFound] = useState(false);
    const [data, setData] = useState([]);

    const GetProducts = async (keyword) => {
        try {
            setIsLoading(true)
            const response = await axios.get(`${process.env.REACT_APP_API_URL}/products/search?keyword=${keyword}`, { withCredentials: true })
            if (response) {
                const availableProducts = response?.data?.filter(product => product.available);
                setData(availableProducts)
                setIsLoading(false)
                setShowNotFound(true)
            }
        } catch (error) {
            console.log(error)
        }
    }
    useEffect(() => {
        if (keyword) {
            GetProducts(keyword)
        }
    }, [keyword]);

    useEffect(() => {
        document.title = 'Miniaturizando: Search Engine'
    }, []);
    return (
        <div id='search-results'>
            <div className="container py-5" style={{ minHeight: '88vh' }}>
                <div className="row">
                    <div className="col-md-12">
                        <div className="row justify-content-center">
                            <div className="col-md-6 mb-2">
                                <SearchBox />
                            </div>
                        </div>
                        {isLoading && (
                            <>
                                <SearchPlaceholder />
                                <SearchPlaceholder />
                                <SearchPlaceholder />
                                <SearchPlaceholder />
                                <SearchPlaceholder />
                                <SearchPlaceholder />
                                <SearchPlaceholder />
                                <SearchPlaceholder />
                                <SearchPlaceholder />
                                <SearchPlaceholder />
                            </>
                        )}

                        {data && data.length > 0 ? data.map((item, index) => (
                            <Link
                                key={index}
                                to={{
                                    pathname: `/product/${item.reference}/${item?.name
                                        .replace(/[^a-zA-Z0-9 ]/g, "")
                                        .replaceAll(" ", "-")}`,
                                }}
                                className='product-link'
                                state={{ item: item }}
                            >
                                <div className='card shadow mb-2 p-2 result' key={index}>
                                    <div className="row align-items-center">
                                        <div className="col-md-2">
                                            <img src={item?.image} alt={item?.name} className="result-img" />
                                        </div>
                                        <div className="col-md-10">
                                            <h3 className='title mt-2'>{item?.name}</h3>
                                            <div className="row">
                                                <div className="col-md-3"><p className='title mb-0'>Price: <span class="badge text-bg-primary"> {`${user?.currency === 'BRL' ? `${formatAsBrazilianCurrency(brazilCurrencyConvert((item?.price / 100), user.currencyRate.BRL).toFixed(2))}` : `€${(item?.price / 100).toFixed(2)}`}`}</span></p></div>
                                                <div className="col-md-3"><p className='title mb-0'>Reference: {item?.reference}</p></div>
                                                <div className="col-md-3"><p className='title mb-0'>Available: {item?.available === true ? <BsCheckCircleFill className='text-success' /> : <RxCrossCircled className='bg-danger text-white rounded-circle' />}</p></div>
                                                <div className="col-md-3"><p className='title mb-0'><img src={item?.companyLogo} alt={item?.company} style={{ height: '50px' }} /></p></div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </Link>
                        )) :
                            <div>
                                {showNotFound &&
                                    <>
                                        <h2>Nothing found</h2>
                                        <p>Sorry, but nothing matched your search terms. please try again with some different keywords.</p>
                                    </>
                                }
                            </div>
                        }
                    </div>
                </div>
            </div>
        </div>
    )
}
