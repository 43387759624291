import axios from 'axios';
import moment from 'moment/moment';
import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { Link, useNavigate, useParams } from 'react-router-dom'
import { setCart } from '../../redux/userSlice';
import { formatAsBrazilianCurrency, brazilCurrencyConvert } from '../../utils/Config';

export default function OrderSuccess() {
    const Params = useParams()
    const navigate = useNavigate()
    const orderId = Params.orderId;
    const dispatch = useDispatch()
    const isLogged = useSelector((state) => state.user.isLogged) || '';
    useEffect(() => {
        if (isLogged) {
        } else {
            navigate('/')
        }
    }, [isLogged, navigate])
    const [orderDetails, setOrderDetails] = useState();
    const urlParams = new URLSearchParams(window.location.search);

    const updateOrder = async (data) => {
        const response = await axios.put(`${process.env.REACT_APP_API_URL}/orders/${orderId}`, data, { withCredentials: true })
        if (response.data) {
            getOrder()
        }
    }
    const getOrder = async (ref) => {
        try {
            const response = await axios.get(`${process.env.REACT_APP_API_URL}/orders/${orderId}`, { withCredentials: true })
            if (response) {
                setOrderDetails(response.data)
            }
        } catch (error) {
            console.log(error)
        }
    }
    useEffect(() => {
        dispatch(setCart([]))
        if (urlParams.has('payment_intent') && urlParams.has('redirect_status')) {
            const paymentIntentId = urlParams.get('payment_intent');
            const redirectStatus = urlParams.get('redirect_status');
            if (redirectStatus === "succeeded") {
                const data = {
                    txCode: paymentIntentId,
                    status: 'paid'
                }
                updateOrder(data)
            }
        }
        getOrder();
        document.title = "Miniaturizando: Order Success"
        //eslint-disable-next-line
    }, [])

    return (
        <div className='container' style={{ minHeight: '88vh' }}>
            <div className="row py-5">
                <div className="col-md-12">
                    <div className="row">
                        <div className="col-md-12 d-flex justify-content-end">
                            <Link to='/orders' className='btn btn-warning text-end'>My Orders</Link>
                        </div>
                    </div>
                    <h2 className='text-success mt-3'>Your order placed successfully.</h2>
                    <div className="row">
                        <div className="col-md-6">
                            <div><strong>Order Number</strong>: <span className='text-danger'>{orderId.substring(orderId.length - 6)}</span></div>
                            <div className="">
                                <strong>Reference</strong>: <span className='text-success'>{orderId}</span>
                            </div>
                            <div className="">
                                <strong>Payment Method</strong>: {(() => {
                                    switch (orderDetails?.payMethod) {
                                        case 'stripe':
                                            return <img src='/images/stripe.png' alt='stripe' style={{ height: '30px' }} />
                                        case 'paypal':
                                            return <img src='/images/paypal.png' alt='paypal' style={{ height: '30px' }} />
                                        default:
                                            return <img src='/images/pix.png' alt='pix' style={{ height: '30px' }} />
                                    }
                                })()}
                            </div>

                        </div>
                        <div className="col-md-6">
                            <div className="">
                                <strong>Order Status</strong>:
                                {(() => {
                                    switch (orderDetails?.status) {
                                        case 'place':
                                            return <span className='badge text-bg-primary text-capitalize ms-2'>{orderDetails?.status}</span>
                                        case 'pending':
                                            return <span className='badge text-bg-warning text-capitalize ms-2'>{orderDetails?.status}</span>
                                        case 'failed':
                                            return <span className='badge text-bg-danger text-capitalize ms-2'>{orderDetails?.status}</span>
                                        case 'paid':
                                            return <span className='badge text-bg-success text-capitalize ms-2'>{orderDetails?.status}</span>
                                        case 'processing':
                                            return <span className='badge text-bg-warning text-capitalize ms-2'>{orderDetails?.status}</span>
                                        case 'shipped':
                                            return <span className='badge text-bg-pink text-capitalize ms-2'>{orderDetails?.status}</span>
                                        case 'delivered':
                                            return <span className='badge text-bg-success text-capitalize ms-2'>{orderDetails?.status}</span>
                                        default:
                                            return <span className='badge text-bg-primary text-capitalize ms-2'>{orderDetails?.status}</span>
                                    }
                                })()}
                            </div>
                            <div className="">
                                <strong>Time</strong>: <span>{moment(orderDetails?.createdAt).format('MMMM Do YYYY, h:mm:ss a')}</span>
                            </div>
                        </div>
                    </div>
                    {orderDetails?.payMethod === "pix" && orderDetails?.status === "place" &&
                        <div className='card p-3 mt-3'>
                            <div className="row">
                                <div className="col-md-12">
                                    <div className="d-flex">
                                        <h4 className='me-2'>Pix Payment</h4>
                                        <img src="/images/pix.png" alt="pix" style={{ height: '30px' }} />
                                    </div>
                                    <div><strong>Amount</strong>: <span className='badge text-bg-success ms-2'>{orderDetails?.currency === 'BRL' ? `${formatAsBrazilianCurrency(parseFloat(orderDetails?.amount).toFixed(2))}` : `€${orderDetails?.amount}`}</span>  </div>
                                    <div><strong>Chave E-mail</strong>: <span class="badge text-bg-warning  fs-6">carlostmsp@gmail.com</span> </div>
                                    <div><strong>Reference</strong>: <span className='text-danger'>{orderId.substring(orderId.length - 6)}</span></div>
                                    <div className="fs-sm"> <strong>Note</strong>: Please send the EXACT amount and Order Number specified, we will check manually and will update your order as soon as possible.</div>
                                </div>
                            </div>
                        </div>
                    }
                    <div className="address row mt-3">
                        <div className="col-md-6">
                            <h4>Billing Details</h4>
                            <div><strong>{orderDetails?.billingAddress?.name}</strong></div>
                            <div className="">
                                {orderDetails?.billingAddress?.address1}, {orderDetails?.billingAddress?.address2}
                            </div>
                            <div className="">
                                {orderDetails?.billingAddress?.state}, {orderDetails?.billingAddress?.country}
                            </div>
                            <div className="">
                                {orderDetails?.billingAddress?.phone}
                            </div>
                        </div>
                        <div className="col-md-6">
                            <h4>Shipping Details</h4>
                            {orderDetails?.shippingAddress ?
                                <div className="">
                                    <div><strong>{orderDetails?.shippingAddress?.name}</strong></div>
                                    <div className="">
                                        {orderDetails?.shippingAddress?.address1}, {orderDetails?.shippingAddress?.address2}
                                    </div>
                                    <div className="">
                                        {orderDetails?.shippingAddress?.state}, {orderDetails?.shippingAddress?.country}
                                    </div>
                                    <div className="">
                                        {orderDetails?.shippingAddress?.phone}
                                    </div>
                                </div>
                                :
                                <div className="">
                                    <p>Same as Billing address</p>
                                </div>
                            }

                        </div>
                    </div>
                    <div className='mt-3'>
                        <h4>Products Details</h4>
                        <table className='table'>
                            <thead>
                                <tr>
                                    <th scope="col" style={{ width: '10%' }}>#</th>
                                    <th scope="col" style={{ width: '53%' }}>Product</th>
                                    <th scope="col" style={{ width: '15%' }}>Price</th>
                                    <th scope="col" style={{ width: '10%' }}>Qty</th>
                                    <th scope="col" style={{ width: '12%' }} className='text-end'>Subtotal</th>
                                </tr>
                            </thead>
                            <tbody>
                                {orderDetails && orderDetails?.products.length > 0 && orderDetails?.products.map((product, index) => (
                                    <tr>
                                        <td>{index + 1}</td>
                                        <td>
                                            <div className="row align-items-center">
                                                <div className="col-md-3">
                                                    <img src={product?.productId?.image} alt={product?.productId?.name} className="img-fluid" />
                                                </div>
                                                <div className="col-md-9">
                                                    {product?.productId?.name}
                                                </div>
                                            </div>
                                        </td>
                                        <td>{orderDetails?.currency === 'BRL' ? `${formatAsBrazilianCurrency(brazilCurrencyConvert((product?.productId?.price / 100), parseFloat(orderDetails?.currencyRate)).toFixed(2))}` : (product?.productId?.price / 100).toFixed(2)} </td>
                                        <td>{product?.qty}</td>
                                        <td className='text-end'>{orderDetails?.currency === 'BRL' ? `${formatAsBrazilianCurrency((brazilCurrencyConvert(((product?.productId?.price) / 100), orderDetails?.currencyRate) * product?.qty).toFixed(2))}` : `€${((parseFloat(product?.productId?.price / 100) * product?.qty)).toFixed(2)}`}</td>
                                    </tr>
                                ))}
                                <tr>
                                    <td colSpan={3}></td>
                                    <td><strong>Shipping</strong></td>
                                    <td className='text-end'><strong>{orderDetails?.currency === 'BRL' ? 'FREE' : <>€{orderDetails?.shipping === 'flat' ? '20.00' : '5.00'}</>}</strong></td>
                                </tr>
                                <tr>
                                    <td colSpan={3}></td>
                                    <td><strong>Discount</strong></td>
                                    <td className='text-end'>€<strong>0.00</strong></td>
                                </tr>
                                <tr>
                                    <td colSpan={3}></td>
                                    <td><strong>Total</strong></td>
                                    <td className='text-end'><strong>{orderDetails?.currency === 'BRL' ? `${formatAsBrazilianCurrency(parseFloat(orderDetails?.amount).toFixed(2))}` : `€${(parseFloat(orderDetails?.amount)).toFixed(2)}`}</strong></td>
                                </tr>
                            </tbody>
                        </table>
                        <p className='text-center'>Thank you for shopping with Miniaturizando.com</p>
                    </div>
                </div>
            </div>
        </div >
    )
}
